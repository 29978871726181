import { initializeApp, getApps } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAkGVoggPq5JLVHUpAh-obZfRM4Auqf1mM",
  authDomain: "smart-booking-new-ebefc.firebaseapp.com",
  projectId: "smart-booking-new-ebefc",
  storageBucket: "smart-booking-new-ebefc.appspot.com",
  messagingSenderId: "34580043202",
  appId: "1:34580043202:web:1d41909e8dffc14c97b25b",
  measurementId: "G-H6WRJYF4KF"
};

// Initialize Firebase
const apps = getApps();
const app = !apps.length ? initializeApp(firebaseConfig) : apps[0]
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);
export default messaging;
