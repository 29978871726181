export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "language": {
    "eng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])}
  },
  "tabFilter": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "close_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close to Me"])},
    "top_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Rated"])},
    "most_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Viewed"])},
    "lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest Price"])},
    "highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest Price"])}
  },
  "header": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "becomeVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become Vendor"])},
    "addService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Service"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
    "termOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term of Use"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "apartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartments"])},
    "farms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farms"])},
    "hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels"])},
    "resorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resorts"])},
    "lounges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lounges"])},
    "stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadium"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps"])},
    "weddingHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding Halls"])},
    "chalets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chalets"])}
  },
  "menuDropdown": {
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "myServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Services"])},
    "serviceBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Bookings"])},
    "myBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my Bookings"])},
    "becomeVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become Vendor"])},
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourites"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "priceDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Display"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "priceSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAR"])},
    "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km"])},
    "arbic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arabic "])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
    "bookingRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Requests"])}
  },
  "homePage": {
    "whatWeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT CAN WE DO FOR YOU?"])},
    "exploreMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPLORE MORE ON SMARTBOOKING PLATFORM"])},
    "exploreCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPLORE CITIES"])},
    "InstantReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Reservation"])},
    "CustomerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer service"])},
    "Advancedandintelligentsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced and intelligent search"])},
    "SafePaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe Payment Methods"])},
    "TrustedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted Reviews"])},
    "Photosandvideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos and videos"])},
    "MultipleandsecurepaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple and secure payment Methods"])},
    "Seephotosfromyourplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See photos from your place"])},
    "Allkinds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All kinds of search at your fingertips."])},
    "WeSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support you throughout the week."])},
    "YourReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation is 100% guaranteed."])},
    "CertifiedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified reviews and comments."])}
  },
  "searchHeader": {
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
    "farms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farms"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "resorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resorts"])},
    "lounges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lounges"])},
    "stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadium"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps"])},
    "weddingHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding Halls"])},
    "chalets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chalets"])},
    "searchLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Location"])},
    "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a category"])},
    "chooseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a city"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
    "cityHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your city name"])},
    "categoryHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your category name"])}
  },
  "signUp": {
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create your account"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGNUP"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])}
  },
  "login": {
    "loginAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Sign In to continue"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGNUP"])},
    "signUpCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "dontAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an Account?"])}
  },
  "otp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code?"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code that you received through SMS"])},
    "subTitleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code that you received through Email"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])}
  },
  "verify": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTER YOUR NAME"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please create your account Name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
  },
  "favourites": {
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAVOURITES"])}
  },
  "serviceBookings": {
    "serviceBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICE BOOKINGS"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can your service booking here"])}
  },
  "myBookingPage": {
    "myBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY BOOKINGS"])},
    "bookingRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
    "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
  },
  "myServicePage": {
    "myServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY SERVICES"])}
  },
  "chatPage": {
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MESSAGES"])},
    "allMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL MESSAGES"])},
    "searchMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Messages"])}
  },
  "inputs": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY PROFILE"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "IDNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Of Birth"])},
    "securityAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["security amount"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "discountPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Percentage"])}
  },
  "AddService": {
    "addService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Service"])},
    "swimmingWithWaterGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swimming With Water Games"])},
    "subAddService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add details for new service"])},
    "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
    "fewQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Few questions about type of service, rooms, bath and amenities"])},
    "serviceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Description"])},
    "Settherules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the rules, post best pictures and describe the scene to guests"])},
    "priceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price details"])},
    "SetPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set prices, availability and booking settings"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "serviceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service type"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "room&guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms & Guests"])},
    "Amentities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amentities"])},
    "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports"])},
    "gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gym"])},
    "billiard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billiard"])},
    "tennisCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennis court"])},
    "airHockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Hockey"])},
    "soccerField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soccer Fields"])},
    "volleyBall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball"])},
    "trampoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trampoline"])},
    "leisure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure"])},
    "steamBath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steam bath"])},
    "indoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor"])},
    "outDoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["outdoor"])},
    "swimmingPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swimming pool"])},
    "swimmingPoolHeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swimming pool heater"])},
    "playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["playground"])},
    "hotTub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot tub"])},
    "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities"])},
    "homeAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Amenities"])},
    "24hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 hours"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "wheelChair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wheelchair"])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
    "entrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrance"])},
    "doorman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorman"])},
    "contenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenue"])},
    "laserLights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laserLights"])},
    "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speakers"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stage"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wifi"])},
    "tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tv"])},
    "saftyBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safty Box"])},
    "elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevator"])},
    "waterGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Games"])},
    "sandGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sand Games"])},
    "airSlider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Slider"])},
    "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air"])},
    "conditioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditioning"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking"])},
    "lampPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lampPost"])},
    "hairStylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair Stylist"])},
    "spa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spa"])},
    "outDoorSwimmingPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["outdoor SwimmingPool"])},
    "inDoorSwimmingPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indoor SwimmingPool"])},
    "24-HourRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-Hour Request"])},
    "privateEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Entrance"])},
    "indoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Seating"])},
    "outdoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Seating"])},
    "additionSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addition Seating"])},
    "livingRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Living Room"])},
    "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Date"])},
    "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Date"])},
    "chalets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chalets"])},
    "resorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resorts"])},
    "stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadium"])},
    "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartments"])},
    "lounges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lounges"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "wedding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding"])},
    "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halls"])},
    "farms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farms"])}
  },
  "serviceDescription": {
    "serviceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Description"])},
    "rules&Safty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules & Safty"])},
    "parties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events & Parties allowed "])},
    "smoking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoking Allowed"])},
    "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets Allowed "])},
    "suitableGrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable For Grooms"])},
    "fireAlaram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Alaram "])},
    "carbonMonoxideAlarams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon Monoxide Alarams"])},
    "fireExtinguisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fire Extinguisher"])},
    "firstAidKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first Aid Kit"])},
    "suitableForChildrens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable For Childrens"])},
    "suitableForInfants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suitable For Infants"])},
    "kitchenUtensils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen Utensils"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen"])},
    "diningTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dining Table"])},
    "microwave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microwave"])},
    "coffeeMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CoffeeMachine"])},
    "firePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FirePlace "])},
    "kettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kettle"])},
    "hotBeverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HotBeverage "])},
    "oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oven"])},
    "stove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stove"])},
    "refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerator"])},
    "roomsBath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms Bath"])},
    "hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hanger"])},
    "soapTissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SoapTissue"])},
    "shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shampoo"])},
    "towels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Towels"])},
    "massageChair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MassageChair"])},
    "extraPillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraPillow"])},
    "hairDryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hairDryer "])},
    "bedSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed Sheet"])},
    "washer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washer"])},
    "iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron"])},
    "outdoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out door"])},
    "grill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grill "])},
    "greenYard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Yard"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "address&Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address & Location"])},
    "safty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["safty"])},
    "closet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closet"])},
    "mandiPit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MandiPit"])},
    "balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcony"])},
    "freeMeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Meal"])},
    "breakFast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break Fast"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinner"])},
    "additionalRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdditionalRules"])},
    "barbequeCorner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbeque Corner"])}
  },
  "pricing": {
    "priceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Details"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "daysPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days Price"])},
    "weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekdays"])},
    "sunWed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun-Wed"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "checkIn&CheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Check-in & Check-out times"])},
    "bookingSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Settings"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "families&": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Families &"])},
    "singles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singles"])},
    "familiesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Families Only"])},
    "ladiesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladies Only"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "bookingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Type"])},
    "bookingTypeNameHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Type"])},
    "cancellationPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy"])},
    "cancellationPolicyNameHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Policy"])},
    "securityDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Deposit"])},
    "lastMinuteDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Minute Discount"])},
    "instantBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Booking"])},
    "24-HourRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-Hour Request"])},
    "flexible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible"])},
    "flexibleSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cancellation Policy allows guests to cancel up to 24 hours prior to check-in date and receive a full refund."])},
    "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderate"])},
    "moderateSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cancellation Policy allows guests to cancel up to 3 days prior to check-in date and receive a full refund."])},
    "strict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strict"])},
    "strictSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cancellation Policy allows guests to cancel up to 7 days prior to check-in date and receive a full refund."])},
    "nonRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Refundable"])},
    "nonRefundableSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation policy allows guests to cancel the reservation but booking fees are non-refundable."])},
    "addOns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-Ons"])},
    "namePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Price"])},
    "birthdayArrangements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday Arrangements"])},
    "weddingArrangements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding Arrangements"])},
    "sleepover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleepover"])},
    "buffet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffet"])},
    "photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographer"])},
    "weddingCake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weddingCake"])},
    "howLongServiceOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long the service will be open for booking?"])}
  },
  "becomeVendor": {
    "becomeVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become Vendor"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an option"])},
    "irep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I represent the"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "mpersonal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a personal"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
    "CommercialIDNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial ID No"])},
    "Uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "commercialIdPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Id Photo"])},
    "idPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Photo"])},
    "idNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Number"])}
  },
  "listingPage": {
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "locationFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Filters"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
    "otherFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Filter"])},
    "availableOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available only"])},
    "offersOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offers only"])},
    "searchByPropertyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by property name "])},
    "searchByUintName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search by uint name"])},
    "vacationRentalTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation Rental Types"])},
    "hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
    "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm"])},
    "resort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resort"])},
    "chalet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chalet"])},
    "gathernChoicesFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathern Choices Filters"])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique"])},
    "stable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stable"])},
    "priceFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Filters"])},
    "spaceFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space Filters"])},
    "reviewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Filters"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
    "veryGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very good"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "fair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair"])},
    "bedroomFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedroom Filters"])},
    "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bedroom"])},
    "singleBeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Beds"])},
    "doubleBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Bed"])},
    "bathroomFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathroom Filters"])},
    "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrooms"])},
    "soap&Tissues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soap & Tissues"])},
    "shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shampoo"])},
    "tissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissue"])},
    "soap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soap"])},
    "bathTub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathtub"])},
    "capacityFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity Filters"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
    "poolTypeFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pool Type Filters"])},
    "swimmingPoolWithHeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swimming Pool With Heat "])},
    "noPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Pool"])},
    "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wifi"])},
    "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speakers"])},
    "playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playground"])},
    "footBallCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Football Court"])},
    "volleyBallCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volleyball Court "])},
    "categoriesFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories Filters"])},
    "couples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couples"])},
    "familiesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Families only"])},
    "singlesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singles Only"])},
    "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travelers"])},
    "kitchenFacilitiesFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen Facilities Filters"])},
    "oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oven"])},
    "refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerator"])},
    "microwave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microwave"])},
    "barbequeCorner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbeque Corner"])},
    "coffeMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffe Machine"])},
    "kitchenUtensils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchen Utensils"])},
    "woodStove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood Stove"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "indoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor With Barrier"])},
    "indoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Without Barrier"])},
    "outdoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor With Barrier"])},
    "outdoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Without Barrier"])},
    "swimmingWithWaterGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swimming With Water Games"])},
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "CheckInOutFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in & Check-out Filters"])}
  },
  "placeholders": {
    "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Rooms"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
    "bedrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrooms"])},
    "doubleBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doubleBed"])},
    "singleBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["singleBed"])},
    "singleBeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SingleBeds"])},
    "maxGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max Guest"])},
    "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bathrooms"])},
    "dressingRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressing Rooms"])},
    "stands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stands"])},
    "NumOfMajlesTents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NumOf Majles Tents"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Type"])},
    "numberUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Units"])},
    "numberChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Children"])},
    "serviceNameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Name (English)"])},
    "serviceNameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Name (Arabic)"])},
    "secondaryPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Phone"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "PropertyDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Description (English)"])},
    "PropertyDescriptionAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Description (Arabic)"])},
    "addserviceimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add service image"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "propertyNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Number"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "landMark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landmark"])},
    "pitchGrassType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pitch Grass Type"])},
    "numberAdults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Adults"])},
    "numOfTents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Of Tents"])},
    "indoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Seating"])},
    "outdoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Seating"])},
    "outdoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Without Barrier"])},
    "outdoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor With Barrier"])},
    "indoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Without Barrier"])},
    "indoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor With Barrier"])},
    "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["travelers"])},
    "descriptionInEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description In English"])},
    "descriptionInArabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description In Arabic"])},
    "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destination"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direction"])},
    "siteInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siteInfo"])},
    "childrenAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children Allowed"])},
    "maleRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male Rooms"])},
    "femaleRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["female Rooms"])},
    "maxSittingCapacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxSittingCapacity"])}
  },
  "detailPage": {
    "vendorProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor Profile"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities"])},
    "leisure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure"])},
    "homeAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Amenities"])},
    "checkInDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in date"])},
    "checkOutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out date"])},
    "checkInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in time"])},
    "checkOutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out time"])},
    "perNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Night"])},
    "perHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Hour"])},
    "Howmanyhours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many hours"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "Selectdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])}
  },
  "success": {
    "requestSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Submitted Successfully!"])}
  },
  "footer": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Safa Tower , Al Safa District, Prince Majid Road Jeddah - KSA"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["551900727"])},
    "phone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["501776931"])},
    "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUICK LINKS"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms Of Use"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "FAQs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
    "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CITIES"])},
    "makkah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makkah"])},
    "jeddah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeddah"])},
    "riyadh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riyadh"])},
    "taif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taif"])},
    "madinah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madinah"])},
    "abha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abha"])},
    "dammam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dammam"])},
    "buraidah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buraidah"])},
    "tabuk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabuk"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOLLOW US"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
    "footerLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved © 2023 SMARTBOOKING Platform"])}
  },
  "error": {
    "numberError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number Error!"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOT FOUND"])}
  },
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "Whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])},
  "Quick Conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Conversation"])},
  "Telephone Conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone Conversation"])},
  "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
  "SAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAR"])},
  "ViewAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Images"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "InstantAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Booking Available"])},
  "24Available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Hours Booking Available"])},
  "InstantBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant Booking"])},
  "24HoursBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 Hours Booking"])}
}