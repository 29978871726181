<template>
  <section class="container hotel-slider">
    <v-carousel
      v-if="banners.length > 0"
      light
      style="height: auto"
      hide-delimiter-background
      hide-delimiters
      :continuous="true"
      :show-arrows="true"
      prev-icon="mdi-arrow-left"
      next-icon="mdi-arrow-right"
    >
      <template v-slot:prev="{ props }">
        <v-btn variant="elevated" color="#FFF" @click="props.onClick"
          >&lt;</v-btn
        >
      </template>
      <template v-slot:next="{ props }">
        <v-btn variant="elevated" color="#FFF" @click="props.onClick"
          >&gt;</v-btn
        >
      </template>
      <v-carousel-item v-for="n in totalSlides" :key="n">
        <a :href="banners[n - 1]?.link" class="hotel-image" target="_blank">
          <img class="small-banner-img" :src="banners[n - 1]?.websiteImage" />
        </a>
        <a
          v-if="screenWidth > 700"
          :href="banners[n]?.link"
          class="hotel-image"
          target="_blank"
        >
          <img class="small-banner-img" :src="banners[n]?.websiteImage" />
        </a>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
// import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  name: "HotelSlider",
  components: {
    // Carousel,
    // Slide,
    // Navigation,
  },
  data: () => ({
    banners: [],
    totalSlides: 0,
    screenWidth: 0,
    // carousel settings
    settings: {
      itemsToShow: 3.3,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      300: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // 700px and up
      700: {
        itemsToShow: 2.3,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 2.3,
        snapAlign: "start",
      },
    },
  }),
  mounted() {
    this.getData();
    this.updateScreenWidth();
    this.onScreenResize();
  },
  methods: {
    getData() {
      this.$axios.get("user/banner").then((res) => {
        this.banners = res.data;
        this.totalSlides = Math.ceil(this.banners.length / 2);
        console.log(this.totalSlides);
      });
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.v-btn .v-btn__content .v-icon {
  color: black;
}
.carousel__slide {
  position: relative;
}
.hotel-slider {
  padding: 30px 0;
  min-height: 200px;
}
.small-banner-img {
  width: 520px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}
@media (max-width: 700px) and (min-width: 320px) {
  .hotel-slider {
    padding: 10px 0 !important;
  }
  .small-banner-img {
    width: 100vw;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
