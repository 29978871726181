<template>
  <div class="container smart-booking">
    <div class="smart-title">
      <h1>{{ $t("homePage.exploreCities") }}</h1>
    </div>
    <div :class="['card-container', { 'rtl-set': $t('lang') == 'ar' }]">
      <CategoryModel
        :class="{ 'rtl-set': $t('lang') == 'ar' }"
        :model="categoryModel"
        :city="selectedCity"
        @search="searchNow"
        @close="close"
      />
    </div>
  </div>

  <section class="container booking-slider">
    <carousel
      :settings="settings"
      :wrap-around="true"
      :breakpoints="breakpoints"
      :class="['carousel-container', { 'ltr-set': $t('lang') == 'ar' }]"
    >
      <slide
        v-for="(item, index) in smartBooking.filter(
          (cty) => cty.status == 'active'
        )"
        :key="index"
      >
        <div class="booking-image" @click="goToUrl(item)">
          <img :src="item?.image" />
          <div class="detail">
            <p>{{ $t("lang") == "ar" ? item.name_ar : item.name }}</p>
          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <!-- <pagination /> -->
      </template>
    </carousel>
  </section>
  <br /><br />
</template>

<script>
import CategoryModel from "@/components/home/categoryModel.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  name: "ExploreCity",
  components: {
    CategoryModel,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 7.3,
        snapAlign: "center",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        300: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        700: {
          itemsToShow: 3.3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.3,
          snapAlign: "start",
        },
      },
      categoryModel: false,
      smartBooking: [],
      //
      selectedCity: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$axios.get(`services/cities`).then((res) => {
        console.log(res.data);
        this.smartBooking = res.data;
        this.loading = false;
      });
    },
    searchNow(val) {
      this.$router.push(
        `search?city=${this.selectedCity.name}&categoryId=${val._id}&page=1`
      );
    },
    goToUrl(val) {
      console.log(val);
      this.selectedCity = val;
      this.categoryModel = true;
    },
    close() {
      this.categoryModel = false;
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 0;
}
.smart-booking {
  padding: 10px 0;
}
.booking-image {
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 17px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}
.booking-image img {
  height: 230px;
  width: 300px;
  border-radius: 17px;
  object-fit: cover;
}
@media (max-width: 700px) and (min-width: 320px) {
  .card-container {
    margin: 0 10px;
    padding: 10px 0;
  }
}
</style>
