<template>
  <section class="logo">
    <router-link to="/">
      <img
        v-if="settings?.websiteLogo"
        :src="settings?.websiteLogo"
        alt="logo"
      />
      <img v-else src="../../assets/images/logo.svg" alt="logo" />
    </router-link>
  </section>
</template>

<script>
export default {
  name: "webLogo",
  data() {
    return {
      settings: {},
    };
  },
  methods: {
    getFooterInfo() {
      this.$axios.get("admin/get-setting").then((res) => {
        this.settings = res.data;
      });
    },
  },
  mounted() {
    this.getFooterInfo();
  },
};
</script>

<style scoped>
.logo {
  width: 180px;
  height: 50px;
  position: relative;
  top: -6px;
}
.logo img {
  object-fit: contain;
}
@media (max-width: 700px) and (min-width: 320px) {
  .logo {
    width: 55px;
    height: 50px;
  }
}
</style>
