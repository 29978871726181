import Cookies from "js-cookie";

var config = () => {
  var cookie = Cookies.get("Authorization");
  if (cookie) {
    var con = {
      Authorization: `Bearer ${cookie}`,
    };
    return con;
  }
};

export default config;
