<template>
  <section class="container hero-slider">
    <SearchHeader />
    <v-carousel
      cycle
      hide-delimiter-background
      hide-delimiters
      v-if="banners.length > 0"
      :continuous="true"
      :show-arrows="false"
      style="height: auto"
    >
      <v-carousel-item
        v-for="banner in banners"
        :key="banner._id"
        :src="banner?.websiteImage"
        cover
        @click="clickedItem(banner.link)"
      ></v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import SearchHeader from "@/components/SearchHeader.vue";
export default {
  name: "HeroSlider",
  components: {
    SearchHeader,
  },
  data: () => ({
    banners: [],
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 1,
        snapAlign: "start",
      },
    },
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios.get("user/main-banner").then((res) => {
        this.banners = res.data;
      });
    },
    clickedItem(link) {
      window.open(link);
    }
  },
};
</script>

<style scoped>
.carousel-container {
  margin-top: 15px;
}

.hero-slider {
  padding: 10px 0;
  min-height: 200px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 10px;
  /* top: 80px; */
}
/* .hero-slider .search {
  position: absolute;
  right: 0;
  top: 0px;
  left: 0;
}*/

.hero-slider .primary-search {
  box-shadow: 0px 4px 8px -3px #cfcfcf6b !important;
}
.layout {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#9f9f9f21, #00000094);
  border-radius: 25px;
  height: 170px;
}
.carousel__slide {
  position: relative;
  height: 440px;
  border-radius: 16px;
  overflow: hidden;
}
.carousel__slide img {
  width: 100%;
  object-fit: cover;
}
@media (max-width: 700px) and (min-width: 320px) {
  .hero-slider .search {
    position: unset !important;
  }
}
</style>
