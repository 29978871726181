<template>
  <section :class="['search', { 'set-lang': $t('lang') == 'ar' }]">
    <div :class="['primary-search', { shadowFull: $route.path == '/' }]">
      <!-- block -->
      <div class="search-block">
        <div class="head-category">
          <div class="img">
            <img src="./../assets/images/locationsearch.svg" alt="search" />
          </div>
          <div>
            <span>{{ $t("searchHeader.chooseCity") }}</span>
          </div>
        </div>
        <div class="location" id="input">
          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="true"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <input
                  type="text"
                  :placeholder="$t('searchHeader.city')"
                  v-model="selected.city"
                  v-bind="props"
                />
              </template>

              <v-card min-width="300">
                <div :class="['city-search', { arabic: $t('lang') == 'ar' }]">
                  <v-text-field
                    :label="$t('searchHeader.city')"
                    v-model="search.city"
                    :hint="$t('searchHeader.cityHint')"
                    persistent-hint
                    variant="outlined"
                    @input="changeCity"
                    autofocus
                    :reverse="$t('lang') == 'ar'"
                  ></v-text-field>
                </div>

                <v-list>
                  <v-list-item
                    v-for="(city, c) in cities"
                    @click="selectedCity(city)"
                    :key="c"
                  >
                    <v-list-item-content
                      :class="[{ 'text-right': $t('lang') == 'ar' }]"
                    >
                      <v-list-item-title
                        class="headline mb-2"
                        v-text="$t('lang') == 'ar' ? city.name_ar : city.name"
                      >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </div>

      <div class="search-block">
        <div class="head-category">
          <div class="img">
            <img src="./../assets/images/locationsearch.svg" alt="search" />
          </div>
          <div>
            <span>{{ $t("searchHeader.chooseCategory") }}</span>
          </div>
        </div>
        <div class="location" id="input">
          <div class="text-center">
            <v-menu
              v-model="menuCategory"
              :close-on-content-click="true"
              location="bottom"
            >
              <template v-slot:activator="{ props }">
                <input
                  type="text"
                  :placeholder="$t('searchHeader.category')"
                  v-model="selected.category"
                  v-bind="props"
                />
              </template>

              <v-card min-width="300">
                <div :class="['city-search', { arabic: $t('lang') == 'ar' }]">
                  <v-text-field
                    :label="$t('searchHeader.category')"
                    v-model="search.category"
                    :hint="$t('searchHeader.categoryHint')"
                    persistent-hint
                    variant="outlined"
                    autofocus
                    @input="changeCategory"
                    :reverse="$t('lang') == 'ar'"
                  >
                  </v-text-field>
                </div>

                <v-list>
                  <v-list-item
                    v-for="(category, c) in categories"
                    @click="selectedCategory(category)"
                    :key="c"
                    :prepend-avatar="
                      category.image ? category.image : cat_placeholder
                    "
                  >
                    {{
                      $t("lang") == "ar"
                        ? category.category_name_ar
                        : category.category
                    }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </div>

      <!-- block -->
      <div class="search-block">
        <div class="head-category">
          <div class="img">
            <img src="./../assets/images/checkin.svg" alt="search" />
          </div>
          <div>
            <span>{{ $t("searchHeader.checkIn") }}</span>
          </div>
        </div>
        <div class="date-picker">
          <Datepicker
            v-model="search.checkIn"
            :placeholder="$t('searchHeader.selectDate')"
            :format="format"
            @update:model-value="handleCheckInDate"
            :min-date="new Date()"
          />
        </div>
      </div>
      <!-- block -->
      <div class="search-block">
        <div class="head-category">
          <div class="img">
            <img src="./../assets/images/checkin.svg" alt="search" />
          </div>
          <div>
            <span>{{ $t("searchHeader.checkOut") }}</span>
          </div>
        </div>
        <div class="date-picker">
          <Datepicker
            v-model="search.checkOut"
            :placeholder="$t('searchHeader.selectDate')"
            :format="format"
            :disabled-week-days="disableWeekdays"
            :min-date="minDate"
          />
        </div>
      </div>
      <!-- block -->
      <div class="search-block search-btn" @click="searchNow">
        <div class="head-category">
          <div class="img">
            <img src="./../assets/images/icon-search.svg" alt="search" />
          </div>
          <div>
            <span>{{ $t("searchHeader.search") }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
const date = ref();

export default {
  data() {
    const cat_placeholder = require("../assets/images/all-category.svg");
    return {
      cat_placeholder,
      search: {},
      selected: {},
      searchCity: "Jeddah",
      searchModel: false,
      dropdownCities: false,
      // dropdown
      searchCities: "",
      cities: [],
      allCities: [],
      categories: [],
      allCategories: [],
      //
      date: date,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      //
      menuCategory: false,
      //
      disableWeekdays: [0, 1, 2, 3, 4, 5, 6],
      minDate: undefined,
    };
  },
  computed: {
    citiesFilter() {
      var searchCities = this.cities;
      return searchCities.filter((city) =>
        city.toLowerCase().startsWith(this.searchCities.toLowerCase())
      );
    },
  },
  components: {
    Datepicker,
  },
  methods: {
    handleCheckInDate(modelData) {
      if (modelData) {
        this.disableWeekdays = [];
        this.minDate = new Date(
          modelData.getFullYear(),
          modelData.getMonth(),
          modelData.getDate() + 1
        );
        this.search.checkOut = null;
      }
    },
    changeCategory() {
      const text = this.search.category;
      const results = this.allCategories.filter((i) =>
        i.category.toLowerCase().match(new RegExp(text.toLowerCase()))
      );
      this.categories = results;
    },
    changeCity() {
      const text = this.search.city;
      const results = this.allCities.filter(
        (i) =>
          i.name.toLowerCase().match(new RegExp(text.toLowerCase())) ||
          i.name_ar.toLowerCase().match(new RegExp(text.toLowerCase()))
      );
      this.cities = results;
    },
    openDropdown() {
      this.dropdownCities = true;
    },
    format(date) {
      let dayName = moment(date).format("ddd");
      let monthName = moment(date).format("MMMM");
      let day = moment(date).format("DD");
      let year = moment(date).format("YYYY");
      let finalOutput = `${this.$t(dayName)}, ${day} ${this.$t(
        monthName
      )} ${year}`;
      return finalOutput;
    },
    selectedCity(city) {
      this.selected.city = this.$t("lang") == "ar" ? city.name_ar : city.name;
      this.searchCity = city.name;
      this.search.city = "";
      this.cities = this.allCities;
    },
    selectedCategory(category) {
      this.selected.category =
        this.$t("lang") == "ar" ? category.category_name_ar : category.category;
      this.selected.categoryId = category._id;
      this.search.category = "";
      this.categories = this.allCategories;
    },
    getDateFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    searchNow() {
      const selected = JSON.parse(JSON.stringify(this.selected));
      const search = JSON.parse(JSON.stringify(this.search));
      var data = {
        city: this.searchCity,
        categoryId: selected.categoryId,
        checkIn: this.getDateFormat(search.checkIn),
        checkOut: this.getDateFormat(search.checkOut),
      };
      var url = this.constructURL("/search?", data) + "&page=1";
      this.$router.push(url);
    },
    constructURL(url, fl) {
      const searchParmas = new URLSearchParams();
      Object.entries(fl).forEach(([key, value]) => {
        if (value && value !== "undefined" && value.length > 0) {
          searchParmas.set(key, value);
        }
      });
      return `${url}${searchParmas.toString()}`;
    },
    showModel() {
      this.searchModel = !this.searchModel;
    },
    async getData(city, category, checkIn, checkOut) {
      const cityRes = await this.$axios.get(`/services/cities`);
      if (cityRes.data) {
        const cities = cityRes.data;
        this.cities = cities;
        this.allCities = cities;
      }
      const categoryRes = await this.$axios.get(`/services/categories`);
      let categories = [];
      if (categoryRes.data) {
        categories = categoryRes.data?.categories;
        categories.unshift({
          category: "All",
          category_name_ar: "الكل",
          image: "",
        });
        this.allCategories = categories;
        this.categories = categories;
      }
      // if (city) {
      //   this.selected.city = city;
      // }
      if (category) {
        const findCategory = categories.find((i) => i._id === category);
        this.selected.category =
          this.$t("lang") == "ar"
            ? findCategory.category_name_ar
            : findCategory.category;
        this.selected.categoryId = category;
      }
      if (checkIn) {
        this.search.checkIn = new Date(moment(checkIn, "DD-MM-YYYY"));
      }
      if (checkOut) {
        this.search.checkOut = new Date(moment(checkOut, "DD-MM-YYYY"));
      }
    },
  },
  mounted() {
    const clickAway = () => {
      this.dropdownCities = false;
    };
    var el = document.getElementById("input");
    document.addEventListener("click", function (event) {
      if (el) {
        var isClickInsideElement = el.contains(event.target);
        if (!isClickInsideElement) {
          clickAway();
        }
      }
    });
    var route = this.$route;
    if (route.name == "search") {
      var { city, checkIn, checkOut, rooms } = route.query;
      this.search.city = city;
      this.search.checkIn = checkIn;
      this.search.checkOut = checkOut;
      this.search.rooms = rooms;
    }

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    this.selected.city = this.$t("lang") == "ar" ? "جدة" : "Jeddah";
    this.selected.category = this.$t("lang") == "ar" ? "الكل" : "All";
    this.search.checkIn = new Date(
      moment(`${day}-${month}-${year}`, "DD-MM-YYYY")
    );
    this.search.checkOut = new Date(
      moment(`${day}-${month}-${year}`, "DD-MM-YYYY").add(1, "days")
    );
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(query) {
        const { city, categoryId, checkIn, checkOut } = query;
        this.getData(city, categoryId, checkIn, checkOut);
      },
    },
    "$i18n.locale": function () {
      setImmediate(() => {
        this.search.checkOut = moment(this.search.checkOut).toDate();
        this.search.checkIn = moment(this.search.checkIn).toDate();
        this.selected.category =
          this.$t("lang") == "ar"
            ? this.categories[0].category_name_ar
            : this.categories[0].category;
        this.selected.city = this.$t("lang") == "ar" ? "جدة" : "Jeddah";
      });
    },
  },
};
</script>

<style src="../style/searchHeader.css"></style>
