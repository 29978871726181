import { createRouter, createWebHistory } from "vue-router";
// const Home = () => import("../views/Home.vue");
import Home from "../views/Home.vue";
const Listings = () =>
  import(/* webpackChunkName: "listing" */ "../views/Listings.vue");
const Detail = () =>
  import(/* webpackChunkName: "detail" */ "../views/Detail.vue");
const Profile = () =>
  import(/* webpackChunkName: "profile" */ "../views/Profile.vue");
const Messages = () =>
  import(/* webpackChunkName: "messages" */ "../views/Messages.vue");
const ServiceBooking = () =>
  import(
    /* webpackChunkName: "servicebooking" */ "../views/ServiceBooking.vue"
  );
const ServiceDetail = () =>
  import(/* webpackChunkName: "servicedetail" */ "../views/ServiceDetail.vue");
const BookingRequest = () =>
  import(
    /* webpackChunkName: "bookingrequest" */ "../views/BookingRequest.vue"
  );
const RequestDetail = () =>
  import(/* webpackChunkName: "reqdetail" */ "../views/RequestDetail.vue");
// vendor
const myBookings = () =>
  import(/* webpackChunkName: "mybookings" */ "../views/MyBooking.vue");
const MyBookingDetail = () =>
  import(/* webpackChunkName: "bookingdetail" */ "../views/BookingDetail.vue");
const Privacy = () =>
  import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue");
const Faqs = () => import(/* webpackChunkName: "faq" */ "../views/Faqs.vue");
const Terms = () =>
  import(/* webpackChunkName: "terms" */ "../views/Terms.vue");
const MyServices = () =>
  import(/* webpackChunkName: "myservices" */ "../views/MyServices.vue");
const Favourites = () =>
  import(/* webpackChunkName: "favorites" */ "../views/Favourites.vue");
const Search = () =>
  import(/* webpackChunkName: "search" */ "../views/SearchView.vue");
const SuccessPage = () =>
  import(/* webpackChunkName: "success" */ "../views/successPage.vue");
const ContactPage = () =>
  import(/* webpackChunkName: "contract" */ "../views/ContactPage.vue");
const LoginVendorPage = () =>
  import(/* webpackChunkName: "loginvendor" */ "../views/LoginVendor.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/:category",
    name: "category",
    component: Listings,
  },
  {
    path: "/:cat/:id",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/booking/:id",
    name: "MyBookingDetail",
    component: MyBookingDetail,
  },
  {
    path: "/my-profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/my-services",
    name: "myservices",
    component: MyServices,
  },
  {
    path: "/my-bookings",
    name: "mybookings",
    component: myBookings,
  },
  {
    path: "/messages",
    name: "messages",
    component: Messages,
  },
  {
    path: "/favourites",
    name: "Favourites",
    component: Favourites,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/faqs",
    name: "faqs",
    component: Faqs,
  },
  {
    path: "/term-conditions",
    name: "terms",
    component: Terms,
  },
  {
    path: "/service-booking",
    name: "ServiceBooking",
    component: ServiceBooking,
  },
  {
    path: "/service-details",
    name: "ServiceDetail",
    component: ServiceDetail,
  },
  {
    path: "/booking-request",
    name: "BookingRequest",
    component: BookingRequest,
  },
  {
    path: "/request-details",
    name: "RequestDetail",
    component: RequestDetail,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
  },
  {
    path: "/success",
    name: "successPage",
    component: SuccessPage,
  },
  {
    path: "/contact",
    name: "contactPage",
    component: ContactPage,
  },
  {
    path: "/loginvendor",
    name: "loginvendorPage",
    component: LoginVendorPage,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // if (to.path === '/search' || to.path === '/blog') return
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ behavior: "smooth", left: 0, top: 0 });
      }, 100);
    });
  },
});

export default router;
