export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])},
  "language": {
    "eng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])}
  },
  "tabFilter": {
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الافتراضي"])},
    "close_to_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقرب موقعا"])},
    "top_rated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى تقييما"])},
    "most_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى مشاهدة"])},
    "lowest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقل سعرا"])},
    "highest_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى سعرا"])}
  },
  "header": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاشعارات"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "becomeVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك كمقدم خدمة"])},
    "addService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خدمة"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة المتكررة"])},
    "termOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الحجوزات الذكية"])},
    "apartments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلل والشقق المفروشة"])},
    "farms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزارع"])},
    "hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفنادق"])},
    "resorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستراحات"])},
    "lounges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجعات"])},
    "stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملاعب"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخيمات"])},
    "weddingHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعات الأفراح"])},
    "chalets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشاليهات"])}
  },
  "serviceDescription": {
    "serviceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الخدمة"])},
    "rules&Safty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القواعد والأمان"])},
    "parties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسموح الحفلات"])},
    "smoking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسموح التدخين"])},
    "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسموح دخول الحيوانات الأليفة"])},
    "suitableGrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناسب للعرسان"])},
    "fireAlaram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جرس إنذار الحريق"])},
    "carbonMonoxideAlarams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جرس إنذار غاز أول أكسيد الكربون"])},
    "fireExtinguisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طفاية حريق"])},
    "firstAidKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقيبة إسعافات أولية"])},
    "suitableForChildrens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناسب للاطفال (2-14)"])},
    "suitableForInfants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناسب للرضع (أقل من 2)"])},
    "kitchenUtensils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أواني المطبخ"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المطبخ"])},
    "diningTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طاولة الطعام"])},
    "microwave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الميكرويف"])},
    "coffeeMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صانعة القهوة"])},
    "firePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفأة"])},
    "kettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغلاية"])},
    "hotBeverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشروبات الساخنة"])},
    "oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرن"])},
    "stove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقد"])},
    "refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاجات"])},
    "roomsBath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحمامات"])},
    "hanger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العلاقات"])},
    "soapTissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصابون المناديل"])},
    "shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شامبو"])},
    "towels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المناشف"])},
    "massageChair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كرسي المساج"])},
    "extraPillow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسادات إضافية"])},
    "hairDryer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجفف الشعر"])},
    "bedSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غطاء السرير"])},
    "washer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مغاسل"])},
    "iron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكواة"])},
    "outdoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خارجي"])},
    "grill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شواية"])},
    "greenYard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساحة خضراء"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "address&Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "safty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلامة"])},
    "closet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخزانة"])},
    "mandiPit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفرة مندي"])},
    "balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشرفة"])},
    "freeMeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجبة مجانية"])},
    "breakFast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجبة افطار"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غداء"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وجبة عشاء"])},
    "additionalRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاختبار القواعد"])},
    "barbequeCorner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ركن المشويات"])}
  },
  "pricing": {
    "priceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل السعر"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسعار"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "daysPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الأيام"])},
    "weekdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام الأسبوع  (الأحد - الأربعاء)"])},
    "sunWed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد - الأربعاء"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأربعاء"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
    "checkIn&CheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد أوقات تسجيل الوصول والمغادرة"])},
    "bookingSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات الحجز"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمة"])},
    "families&": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العائلات و"])},
    "singles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفردي"])},
    "familiesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعائلات فقط"])},
    "ladiesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نساء فقط"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفيات أخرى"])},
    "bookingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الحجز"])},
    "bookingTypeNameHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم نوع الحجز هنا"])},
    "cancellationPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الإلغاء"])},
    "cancellationPolicyNameHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم سياسة الإلغاء هنا"])},
    "securityDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربون"])},
    "lastMinuteDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخفيض االحجز في الأوقات الأخيرة"])},
    "instantBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز الفوري"])},
    "24-HourRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب حجز خلال 24 ساعة"])},
    "flexible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرن"])},
    "flexibleSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسمح سياسة الإلغاء المرنة للعميل بإلغاء حجزه قبل 24 ساعة من وقت الدخول واسترداد المبلغ المدفوع بالكامل."])},
    "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعتدل"])},
    "moderateSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسمح سياسة الإلغاء المعتدلة للعميل بإلغاء حجزه قبل 3 أيام من وقت الدخول واسترداد المبلغ المدفوع بالكامل."])},
    "strict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصارم"])},
    "strictSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسمح سياسة الإلغاء الصارمة للعميل بالإلغاء قبل 7 أيام من الدخول واسترداد المبلغ المدفوع بالكامل."])},
    "nonRefundable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قابلة للاسترجاع"])},
    "nonRefundableSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسمح سياسة الإلغاء غير القابلة للاسترداد للعميل بإلغاء حجزه ولكن رسوم الحجز غير قابلة للاسترداد."])},
    "addOns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإضافات"])},
    "namePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم السعر"])},
    "birthdayArrangements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيبات حفلات أعياد الميلاد"])},
    "weddingArrangements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترتيبات حفلات الزفاف"])},
    "sleepover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوم"])},
    "buffet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البوفيهات"])},
    "photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصور فوتوغرافي"])},
    "weddingCake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كعكة العرس"])},
    "howLongServiceOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم مدة إتاحة الخدمة للحجز؟"])}
  },
  "menuDropdown": {
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسائل"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
    "myServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتي"])},
    "serviceBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجز الخدمات"])},
    "myBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجوزاتي"])},
    "becomeVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك كمقدم خدمة"])},
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "priceDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض السعر"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسافة"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
    "priceSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال"])},
    "km": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلم"])},
    "arabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العربية"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنجليزية"])},
    "bookingRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات الحجز"])}
  },
  "homePage": {
    "whatWeDo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا يمكننا أن نفعل لك؟"])},
    "exploreMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف المزيد عن منصة مناسك"])},
    "exploreCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف المدن"])},
    "InstantReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز الفوري"])},
    "CustomerService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة العميل"])},
    "Advancedandintelligentsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث متقدم"])},
    "SafePaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق دفع آمنة"])},
    "TrustedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييمات موثوقة"])},
    "Photosandvideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصور ومقاطع الفيديو "])},
    "MultipleandsecurepaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق دفع متعددة وآمنة"])},
    "Seephotosfromyourplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد الصور من مكانك"])},
    "Allkinds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع أنواع البحث في متناول يدك."])},
    "WeSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة العملاء متاحة طوال أيام الأسبوع."])},
    "YourReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجزك مضمون 100٪."])},
    "CertifiedReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التقييمات والتعليقات موثقة."])}
  },
  "searchHeader": {
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغرف"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدخول"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الخروج"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث"])},
    "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلل والشقق المفروشة"])},
    "farms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزارع"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفنادق"])},
    "resorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستراحات"])},
    "lounges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجعات"])},
    "stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملاعب"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخيمات"])},
    "weddingHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعات الأفراح"])},
    "chalets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشاليهات"])},
    "searchLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع البحث"])},
    "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدن"])},
    "chooseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر مدينة"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمة"])},
    "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الخدمة"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التاريخ"])},
    "cityHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسم مدينتك"])},
    "categoryHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل اسم الفئة الخاصة بك"])}
  },
  "signUp": {
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب؟ "])}
  },
  "login": {
    "loginAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك سجل دخولك للمتابعة"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
    "signUpCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "already": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدي حساب بالفعل؟ "])},
    "dontAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب؟"])}
  },
  "verify": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل أسمك"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إنشاء اسم حسابك"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة"])}
  },
  "otp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رمز التحقق"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رمز التحقق المرسل إلى جوالك"])},
    "subTitleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الرمز الذي تلقيته عبر البريد الإلكتروني"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة"])}
  },
  "favourites": {
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])}
  },
  "serviceBookings": {
    "serviceBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجز الخدمات"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك حجز خدمتك هنا"])}
  },
  "myBookingPage": {
    "bookingRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتظر"])},
    "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالية"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافقة"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المكتملة"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملغية"])},
    "myBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجوزاتي"])}
  },
  "myServicePage": {
    "myServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتي"])}
  },
  "chatPage": {
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسائل"])},
    "allMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الرسائل"])},
    "searchMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل البحث"])}
  },
  "inputs": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الشخصية"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأخير"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])},
    "IDNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهوية"])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد"])}
  },
  "AddService": {
    "swimmingWithWaterGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["swimming With Water Games"])},
    "addService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة خدمة"])},
    "subAddService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إضافة تفاصيل الخدمة الجديدة"])},
    "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
    "fewQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة قليلة حول نوع الخدمة والغرف والحمام ووسائل الراحة"])},
    "serviceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الخدمة"])},
    "Settherules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع القواعد وانشر أفضل الصور ووصف المشهد للضيوف"])},
    "priceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل السعر"])},
    "SetPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد الأسعار والتوافر وإعدادات الحجز"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
    "serviceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخدمة"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى الخلف"])},
    "room&guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغرف والضيوف"])},
    "Amentities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الراحة"])},
    "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرياضة"])},
    "gym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالة رياضة"])},
    "billiard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلياردو"])},
    "tennisCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملعب تنس"])},
    "airHockey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهوكي"])},
    "soccerField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملعب كرة قدم"])},
    "volleyBall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كرة طائرة"])},
    "trampoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة الألعاب البهلوانية"])},
    "leisure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الفراغ"])},
    "steamBath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمام بخار"])},
    "indoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخلي"])},
    "outDoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الخارج"])},
    "swimmingPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمام السباحة"])},
    "swimmingPoolHeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسبح مزود بسخان"])},
    "playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملعب"])},
    "hotTub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حوض ساخن"])},
    "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الراحة"])},
    "homeAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الراحة المنزلية"])},
    "24hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 ساعة"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التمكن من"])},
    "wheelChair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كرسي متحرك"])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاص"])},
    "entrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدخل"])},
    "doorman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حارس"])},
    "contenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة"])},
    "laserLights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضواء ليزر"])},
    "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكبرات صوت"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منصة"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واي فاي"])},
    "tv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلفزيون"])},
    "saftyBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صندوق الامان"])},
    "elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصعد"])},
    "waterGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألعاب الرمل"])},
    "sandGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ألعاب الرمل"])},
    "airSlider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزلق هوائي"])},
    "conditioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكيف هواء"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادارة"])},
    "parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مواقف سيارات"])},
    "lampPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمود اضاءة"])},
    "hairStylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصفف شعر"])},
    "spa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إس بي إيه"])},
    "outDoorSwimmingPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسبح خارجي"])},
    "inDoorSwimmingPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسبح داخلي"])},
    "24-HourRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب 24 ساعة"])},
    "privateEntrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدخل خاص"])},
    "indoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجلس داخلي"])},
    "outdoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجلس خارجي"])},
    "additionSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجلس إضافي"])},
    "livingRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرفة المعيشة"])},
    "fromDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من تاريخ"])},
    "toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى تاريخ"])},
    "securityAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ التأمين"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام"])},
    "discountPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسبة الخصم"])},
    "chalets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشاليهات"])},
    "resorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستراحات"])},
    "stadium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملاعب"])},
    "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلل والشقق المفروشة"])},
    "lounges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجعات"])},
    "camps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخيمات"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفنادق"])},
    "wedding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأفراح"])},
    "halls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قاعات"])},
    "farms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزارع"])}
  },
  "becomeVendor": {
    "becomeVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك كمقدم خدمة"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء الاختيار"])},
    "irep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا أمثل"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة"])},
    "mpersonal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا شخصية"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مضيف"])},
    "CommercialIDNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم السجل التجاري"])},
    "Uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الملف"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنسية"])}
  },
  "listingPage": {
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصفيات"])},
    "locationFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الموقع"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحي"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاتجاه"])},
    "otherFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفيات أخرى"])},
    "availableOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط"])},
    "offersOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض فقط"])},
    "searchByPropertyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث بالاسم "])},
    "searchByUintName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث باسم الوحدة"])},
    "vacationRentalTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع إيجارات الإجازات"])},
    "hotels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفنادق"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغرف"])},
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منزل"])},
    "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزارع"])},
    "resort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستراحات"])},
    "chalet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشاليهات"])},
    "gathernChoicesFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع مرشحات الاختيارات"])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريد"])},
    "stable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مستقر"])},
    "priceFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الأسعار"])},
    "spaceFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية المساحة"])},
    "reviewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية التقييمات"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ممتاز"])},
    "veryGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيد جداً"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيد"])},
    "fair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقبول"])},
    "bedroomFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية غرف النوم"])},
    "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرف النوم"])},
    "singleBeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرير مفرد"])},
    "doubleBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرير مزدوج"])},
    "bathroomFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية حسب الحمامات"])},
    "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الحمامات"])},
    "soap&Tissues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصابون و الأنسجة"])},
    "shampoo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شامبو"])},
    "tissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مناديل"])},
    "soap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصابون"])},
    "bathTub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حوض الاستحمام"])},
    "capacityFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الطاقة الاستيعابية"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضيوف"])},
    "poolTypeFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية نوع حوض السباحة"])},
    "swimmingPoolWithHeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسبح مع سخان"])},
    "noPool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مسبح"])},
    "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرافق"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واي فاي"])},
    "speakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكبرات صوت"])},
    "playground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملعب"])},
    "footBallCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملعب كرة قدم"])},
    "volleyBallCourt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملعب كرة الطائرة"])},
    "categoriesFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية الفئات"])},
    "couples": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأزواج"])},
    "familiesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعائلات فقط"])},
    "singlesOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعزاب فقط"])},
    "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسافرون"])},
    "kitchenFacilitiesFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية مكونات المطبخ"])},
    "oven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفرن"])},
    "refrigerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاجات"])},
    "microwave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الميكرويف"])},
    "barbequeCorner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ركن المشويات"])},
    "coffeMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صانعة القهوة"])},
    "kitchenUtensils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أواني المطبخ"])},
    "woodStove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقد خشب"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر"])},
    "indoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخلي مع حاجز"])},
    "indoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخلي بدون حاجز"])},
    "outdoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الهواء الطلق مع حاجز"])},
    "outdoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الهواء الطلق بدون حاجز"])},
    "swimmingWithWaterGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السباحة مع الألعاب المائية"])},
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات"])},
    "CheckInOutFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصفية مواعيد الدخول والخروج"])}
  },
  "placeholders": {
    "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الغرف"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "bedrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرف النوم"])},
    "doubleBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرير مزدوج"])},
    "singleBed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرير مفرد"])},
    "singleBeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سرر مفردة"])},
    "maxGuest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصى لعدد الضيوف"])},
    "bathrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحمامات"])},
    "dressingRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرف الملابس"])},
    "stands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المواقف"])},
    "NumOfMajlesTents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد خيام المجلس"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الغرفة"])},
    "numberUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات"])},
    "numberChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الأطفال"])},
    "serviceNameEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة (اللغة الإنجليزية)"])},
    "serviceNameAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الخدمة (اللغة العربية)"])},
    "secondaryPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جوال إضافي"])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المساحة بالمتر المربع"])},
    "PropertyDescriptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف العقار (انجليزي)"])},
    "PropertyDescriptionAr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف العقار (عربي)"])},
    "addserviceimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف صور الخدمة"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "propertyNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم العقار"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "landMark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلم معروف"])},
    "pitchGrassType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العشب الملعب"])},
    "numberAdults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الكبار"])},
    "numOfTents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الخيام"])},
    "indoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجلس داخلي"])},
    "outdoorSeating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجلس خارجي"])},
    "outdoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor Without Barrier"])},
    "outdoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outdoor With Barrier"])},
    "indoorWithoutBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Without Barrier"])},
    "indoorWithBarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor With Barrier"])},
    "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسافرون"])},
    "descriptionInEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف باللغة الإنجليزية"])},
    "descriptionInArabic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف باللغة العربية"])},
    "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول"])},
    "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحي"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاتجاه"])},
    "siteInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siteInfo"])},
    "childrenAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسموح للأطفال"])},
    "maleRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرف الذكور"])},
    "femaleRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرف البنات"])},
    "maxSittingCapacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أقصى سعة جلوس"])}
  },
  "detailPage": {
    "vendorProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي لمقدم الخدمة"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
    "facilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرافق"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييم"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخريطة"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احجز"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب حجز"])},
    "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الراحة"])},
    "leisure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الفراغ"])},
    "homeAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسائل الراحة المنزلية"])},
    "checkInDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدخول"])},
    "checkOutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الخروج"])},
    "checkInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الدخول "])},
    "checkOutTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الخروج"])},
    "perNight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لليلة"])},
    "perHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الساعة"])},
    "Howmanyhours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم ساعة"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت"])},
    "Selectdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر التاريخ"])}
  },
  "success": {
    "RequestSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال الطلب بنجاح"])}
  },
  "footer": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التواصل"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التواصل معنا"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برج الصفا بالزا - حي الصفا - طريق األمير ماجد – جدة – المملكة العربية السعودية"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["551900727"])},
    "phone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["501776931"])},
    "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط سريعة"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن الحجوزات الذكية"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "FAQs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة المتكررة"])},
    "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدن"])},
    "makkah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكة"])},
    "jeddah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدة"])},
    "riyadh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرياض "])},
    "taif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطائف"])},
    "madinah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
    "abha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبها"])},
    "dammam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدمام"])},
    "buraidah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريدة"])},
    "tabuk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبوك"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعنا"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "youTube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
    "footerLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة © 2023 الحجوزات الذكية"])}
  },
  "error": {
    "numberError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال خطأ!"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير معثور عليه"])}
  },
  "Sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
  "Sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
  "Mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
  "Tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم الثلاثاء"])},
  "Wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأربعاء"])},
  "Thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم الخميس"])},
  "Fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمعة"])},
  "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يناير"])},
  "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر فبراير"])},
  "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمشي"])},
  "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أبريل"])},
  "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن"])},
  "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يونيو"])},
  "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوليو"])},
  "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أغسطس"])},
  "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبتمبر"])},
  "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتوبر"])},
  "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهر نوفمبر"])},
  "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ديسمبر"])},
  "Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
  "Whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الواتساب"])},
  "Quick Conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محادثة سريعة"])},
  "Telephone Conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصال هاتفي"])},
  "Send Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال بريد إلكتروني"])},
  "SAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ريال"])},
  "ViewAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض كل الصور"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يغلق"])},
  "InstantAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز الفوري متاح"])},
  "24Available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 ساعة الحجز متاح"])},
  "InstantBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز الفوري"])},
  "24HoursBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز خلال 24 ساعة"])}
}